import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"section"},[_c(VContainer,[_c(VRow,{attrs:{"justify":_vm.blok.align === 'left' ? 'start' : 'end'}},[_c(VCol,{class:("text-sm-" + (_vm.blok.align)),attrs:{"sm":_vm.blok.sm,"md":_vm.blok.md,"lg":_vm.blok.lg,"xl":_vm.blok.xl}},[(_vm.blok.heading)?_c('h2',{staticClass:"font-weight-semibold text-uppercase",class:{
            'text-h1 text-h1-x-large': _vm.blok.headingSize === 'large',
            'text-h2 text-sm-h1 text-h1-x-large':
              _vm.blok.headingSize === 'medium',
            'text-h4 text-sm-h2 text-md-h1': _vm.blok.headingSize === 'small',
            'text-pre': _vm.isMultilineHeading,
          }},[_c('span',[_vm._v(_vm._s(_vm.blok.heading))])]):_vm._e(),_vm._v(" "),(_vm.blok.description)?_c('p',{staticClass:"mt-4 mt-sm-8 mt-md-12 mb-0 text-h5 text-sm-h4"},[_vm._v("\n          "+_vm._s(_vm.blok.description)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.blok.buttonText)?_c(VBtn,{staticClass:"mt-4 mt-sm-8 mt-md-12 px-12 px-md-16",attrs:{"nuxt":"","to":_vm.to,"title":_vm.blok.buttonTitle,"depressed":"","light":"","rounded":"","x-large":""}},[_vm._v("\n          "+_vm._s(_vm.blok.buttonText)+"\n          "),_c(VIcon,{attrs:{"right":""}},[_vm._v(_vm._s(_vm.mdiArrowRight))])],1):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.blok.imageMobile.filename || _vm.blok.imageDesktop.filename)?_c('ImageBackground',{attrs:{"alt":_vm.blok.imageMobile.alt || _vm.blok.imageDesktop.alt,"src-mobile":_vm.blok.imageMobile.filename || _vm.blok.imageDesktop.filename,"src-desktop":_vm.blok.imageDesktop.filename || _vm.blok.imageMobile.filename}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }