export default (to) => {
  if (!to) return null;
  if (to.linktype === "story" && !to.cached_url) return null;

  const formats = {
    email: `mailto:${to.email}`,
    story: to.cached_url === "home" ? "/" : `/${to.cached_url}/`,
    url: to.cached_url,
    default: to,
  };
  return formats[to.linktype] ?? formats.default;
};
