//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiArrowRight } from "@mdi/js";
import { computed } from "@nuxtjs/composition-api";
import blokProp from "~/helpers/blok-prop";
import formatLink from "~/helpers/format-link";
const __sfc_main = {};
__sfc_main.props = blokProp;

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const isMultilineHeading = computed(() => /\n/.test(props.blok.heading));
  const to = computed(() => formatLink(props.blok.buttonLink));
  return {
    mdiArrowRight,
    isMultilineHeading,
    to
  };
};

export default __sfc_main;
